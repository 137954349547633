import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from '@axios';
import { RootState } from '@redux/reducers';
import {
    Roles,
    UserRequest,
    UserRequestAPI,
    UserResponse,
    UserResponseApi,
} from '@models/users';
import '@extensions/string';
import { ThunkCallback } from '../model/thunk-callback';
import { addNotification } from '../notification';
import { updateLoggedUser } from '../auth';
import { translate } from '@components/i18n';
import {  toQueryParametersWithArray } from '@extensions/object';

export const signUpUser = createAsyncThunk<
    UserResponse,
    ThunkCallback<UserRequest>
>('users/signUpUser', async (request, thunkAPI) => {
    try {
        const response = await axios.post<UserResponseApi>(
            '/usuario',
            new UserRequestAPI(request.data),
        );

        const { data, status } = response;
        if (status === 201 && data) {
            // If they want user register by itself, they will be redirected to login page
            // thunkAPI.dispatch(
            //     signInUser({
            //         email: signUpData.email,
            //         password: signUpData.password,
            //         remember: true,
            //     }),
            // );
            const instance: UserResponseApi = Object.assign(
                new UserResponseApi(),
                data,
            );

            thunkAPI.dispatch(
                addNotification({
                    type: 'success',
                    message: translate('general.registeredUpdated'),
                    title: translate('general.success'),
                    notificationKey: request.notificationKey,
                    callback: request.onSuccess,
                }),
            );

            return instance.toUserResponse();
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (e) {
        console.debug('Error', e);

        thunkAPI.dispatch(
            addNotification({
                type: 'error',
                message: translate('general.errorRegisteringUser'),
                title: translate('general.errorT'),
                notificationKey: request.notificationKey,
            }),
        );

        return thunkAPI.rejectWithValue('');
    }
});

export const fetchUsers = createAsyncThunk<UserResponse[]>(
    'users/fetchUsers',
    async (_, thunkAPI) => {
        try {
            const response = await axios.get<UserResponseApi[]>(
                '/usuario/all',
                {
                    params: {
                        query: '',
                    },
                },
            );

            const { data, status } = response;

            if (status === 200) {
                const result = data.map((u) =>
                    Object.assign(new UserResponseApi(), u),
                );

                return result.map((u) => u.toUserResponse());
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            // TODO: here will be fetch's actions
            return thunkAPI.rejectWithValue(
                translate('general.erroListingUsers'),
            );
        }
    },
);

export const fetchUsersByRoles = createAsyncThunk<UserResponse[], ThunkCallback<Partial<UserRequest>>>(
    'users/fetchUsers',
    async (request, thunkAPI) => {
        try {
            const params = toQueryParametersWithArray({
                papeis: request?.data?.roles || [],
            })
            const response = await axios.get<UserResponseApi[]>(
                '/usuario/bypapeis?' + params,
            );

            const { data, status } = response;

            if (status === 200) {
                const result = data.map((u) =>
                    Object.assign(new UserResponseApi(), u),
                );

                return result.map((u) => u.toUserResponse());
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            // TODO: here will be fetch's actions
            return thunkAPI.rejectWithValue(
                translate('general.erroListingUsers'),
            );
        }
    },
);

export const resetPassword = createAsyncThunk<
    void,
    ThunkCallback<Partial<UserResponse>>
>('users/resetPassword', async (request, thunkAPI) => {
    try {
        const response = await axios.post('/usuario/recuperar-senha', {
            login: request.data.login,
        });

        const { status } = response;

        if (status === 200) {
            thunkAPI.dispatch(
                addNotification({
                    type: 'success',
                    message: translate('general.sendedEmailWithNewPassword'),
                    title: translate('general.success'),
                    notificationKey: request.notificationKey,
                    callback: request.onSuccess,
                }),
            );
        }
    } catch (e) {
        thunkAPI.dispatch(
            addNotification({
                type: 'error',
                message: translate('general.userNotFound'),
                title: translate('general.errorT'),
                notificationKey: request.notificationKey,
            }),
        );
    }
});

export const updateUser = createAsyncThunk<
    UserResponse,
    ThunkCallback<UserResponse>
>('users/updateUser', async (request, thunkAPI) => {
    try {
        const response = await axios.put<UserResponseApi>(
            '/usuario/' + request.data.id,
            new UserRequestAPI(request.data),
        );
        const state = thunkAPI.getState() as RootState;

        const { data, status } = response;

        if (status === 200) {
            const instance: UserResponseApi = Object.assign(
                new UserResponseApi(),
                data,
            );

            thunkAPI.dispatch(
                addNotification({
                    type: 'success',
                    message: translate('general.userUpdated'),
                    title: translate('general.success'),
                    notificationKey: request.notificationKey,
                    callback: request.onSuccess,
                }),
            );

            if (state.login.currentUser.id === request.data.id) {
                thunkAPI.dispatch(updateLoggedUser(instance.toUserResponse()));
            }

            return instance.toUserResponse();
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (e) {
        const { response } = e;
        const { data } = response;
        const { error } = data;
        const { message } = error;

        return thunkAPI.rejectWithValue(message);
    }
});

export const removeUser = createAsyncThunk<number, number>(
    'users/updateUser',
    async (userId, thunkAPI) => {
        try {
            const response = await axios.delete('/usuario/' + userId);
            if (response.status === 200) {
                thunkAPI.dispatch(removeUserState(userId));
                return Promise.resolve(userId);
            }
            return 0;
        } catch (e) {
            const { response } = e;
            const { data } = response;
            const { error } = data;
            const { message } = error;
            return thunkAPI.rejectWithValue(message);
        }
    },
);

interface IUsersState {
    availableUsers: UserResponse[];
    isFetching: boolean;
    isSuccess: boolean;
    isError: boolean;
    errorMessage?: string;
    filter: string;
}

const initialState: IUsersState = {
    availableUsers: [],
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
    filter: '',
};

const usersSlice = createSlice({
    name: 'usersSlice',
    initialState,
    reducers: {
        clearState: (state) => {
            state = { ...initialState };

            return state;
        },
        filterUsers: (state, action: PayloadAction<string>) => {
            state.filter = action.payload;
            return state;
        },
        removeUserState: (state, action: PayloadAction<number>) => {
            state.availableUsers = state.availableUsers.filter(
                (u) => u.id !== action.payload,
            );
            return state;
        },
    },
    extraReducers: {
        [signUpUser.fulfilled.toString()]: (
            state,
            { payload }: PayloadAction<UserResponse>,
        ) => {
            state.isFetching = false;
            state.isSuccess = true;
            state.availableUsers = [...state.availableUsers, payload];
            return state;
        },
        [signUpUser.pending.toString()]: (state) => {
            state.isFetching = true;
            return state;
        },
        [signUpUser.rejected.toString()]: (state, action) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = action.payload;
            return state;
        },

        [updateUser.fulfilled.toString()]: (
            state,
            action: PayloadAction<UserResponse>,
        ) => {
            state.availableUsers = state.availableUsers.map((u) =>
                u.id === action.payload.id ? action.payload : u,
            );
            return state;
        },

        [fetchUsers.pending.toString()]: (state) => {
            state.isFetching = true;
            return state;
        },
        [fetchUsers.rejected.toString()]: (state, action) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = action.payload;
            return state;
        },
        [fetchUsers.fulfilled.toString()]: (
            state,
            { payload }: PayloadAction<UserResponse[]>,
        ) => {
            state.isFetching = false;
            state.isSuccess = true;
            state.availableUsers = payload;
            return state;
        },

        [fetchUsersByRoles.pending.toString()]: (state) => {
            state.isFetching = true;
            return state;
        },
        [fetchUsersByRoles.rejected.toString()]: (state, action) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = action.payload;
            return state;
        },
        [fetchUsersByRoles.fulfilled.toString()]: (
            state,
            { payload }: PayloadAction<UserResponse[]>,
        ) => {
            state.isFetching = false;
            state.isSuccess = true;
            state.availableUsers = payload;
            return state;
        },
    },
});

export const isAuthenticated = (state: RootState): boolean =>
    state.login.isSuccess;

export const userSelector = (state: RootState): IUsersState => state.users;

export const allUsers = ({ users }: RootState): UserResponse[] =>
    users.availableUsers;

export const userValues = ({ users }: RootState): UserResponse[] =>
    users.availableUsers.filter(
        (u) => u.name?.compare(users.filter) || u.email?.compare(users.filter),
    );

interface SelectOption {
    id: number;
    name: string;
}

export const userValuesSelect = ({ users }: RootState): SelectOption[] =>
    users.availableUsers
        .filter(
            (u) =>
                u.name?.compare(users.filter) || u.email?.compare(users.filter),
        )
        .map((u) => ({ id: u.id, name: u.login }));

export const userApprovalValuesSelect = ({
    users,
}: RootState): SelectOption[] =>
    users.availableUsers
        .filter(
            (u) =>
                u.roles.includes(Roles.admin) ||
                u.roles.includes(Roles.approver),
        )
        .filter(
            (u) =>
                u.name?.compare(users.filter) || u.email?.compare(users.filter),
        )
        .map((u) => ({ id: u.id, name: u.login }));

export const userSelect = ({ users }: RootState): SelectOption[] =>
    users.availableUsers
        .filter(
            (u) =>
                u.roles.includes(Roles.admin) ||
                u.roles.includes(Roles.approver) ||
                u.roles.includes(Roles.controlling),
        )
        .map((u) => ({ id: u.id, name: u.login }));

export const { clearState, filterUsers, removeUserState } = usersSlice.actions;

export default usersSlice.reducer;
