import { Drawer, Typography } from 'antd';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageContext } from '../context/producer';
import meta from '../../../meta.json';
import { MenuItem, SubMenuBox, FlexBox } from '../styled';
import { ReactSVG } from 'react-svg';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { authSelector } from '@redux/slices/auth';

export const PageDrawer: React.FC = () => {
    const { t } = useTranslation();
    const pageContext = useContext(PageContext);
    const authState = useSelector(authSelector);

    return (
        <Drawer
            placement="left"
            closable={false}
            onClose={pageContext.toggleDrawer}
            visible={pageContext.drawerOpen}
            getContainer={false}
            style={{ position: 'absolute' }}
        >
            {meta.sideMenus
                ?.filter(
                    (m) =>
                        authState.currentUser.isAdmin ||
                        m.allowedRoles?.some((r) =>
                            authState.currentUser.roles.some((c) => c === r),
                        ),
                )
                .map((m, i) => {
                    const [open, setOpen] = useState(false);

                    const toggleMenu = (): void => {
                        if (m?.subMenus) setOpen((prev) => !prev);
                        else {
                            pageContext.toggleDrawer();
                        }
                    };

                    return (
                        <Link
                            to={!m?.subMenus && m.path}
                            key={`side-menu-${t(m.title)}-${i}-link`}
                        >
                            <MenuItem
                                key={`side-menu-${t(m.title)}-${i}`}
                                className={`${!m?.subMenus && 'has-hover'}`}
                                onClick={toggleMenu}
                            >
                                <FlexBox>
                                    <ReactSVG src={m.icon} />
                                    <Typography.Text>
                                        {t(m.title)}
                                    </Typography.Text>
                                </FlexBox>
                                {m?.subMenus && (
                                    <SubMenuBox className={`${open && 'open'}`}>
                                        {m?.subMenus
                                            ?.filter(
                                                (m) =>
                                                    authState.currentUser
                                                        .isAdmin ||
                                                    m.allowedRoles?.some((r) =>
                                                        authState.currentUser.roles.some(
                                                            (c) => c === r,
                                                        ),
                                                    ),
                                            )
                                            .map((submenu, j) => {
                                                return (
                                                    <Link
                                                        to={submenu.path}
                                                        key={`side-menu-${t(
                                                            submenu.title,
                                                        )}-${i}-${j}-link`}
                                                    >
                                                        <MenuItem
                                                            className="has-hover"
                                                            key={`side-menu-${t(
                                                                submenu.title,
                                                            )}-${i}-${i}`}
                                                        >
                                                            <FlexBox>
                                                                <Typography.Text>
                                                                    {t(
                                                                        submenu.title,
                                                                    )}
                                                                </Typography.Text>
                                                            </FlexBox>
                                                        </MenuItem>
                                                    </Link>
                                                );
                                            })}
                                    </SubMenuBox>
                                )}
                            </MenuItem>
                        </Link>
                    );
                })}
        </Drawer>
    );
};
