import moment from 'moment';
import axios from '@axios';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@redux/reducers';
import { IOrderItemsSelectorState } from '.';
import { OrderRequest } from '@models/order/items/request';
import { PaginatedResponseApi } from '@models/order/items/response';
import { DraftResponseApi } from '@models/order/drafs/response';
import { addNotification } from '../notification';
import { fetchScheduled } from './scheduled';
import { toQueryParameters } from '@extensions/object';
import { CustomerRequest } from '@models/customer';
import { Paginated } from '@models/paginated';
import { translate } from '@components/i18n';
import { formatMoney } from '@utils/money';

export type DraftsResponse = IOrderItemsSelectorState;

export interface DraftsTable {
    id: string;
    draftRef: string;
    name: string;
    condition: string;
    type: string;
    total: number;
    lastUpdate: string;
    bonification?: boolean;
    hasBonification?: string;
    value: number;
    codigoMoeda: string,
}

export const removeDrafts = createAsyncThunk<void, DraftsTable[]>(
    'drafts/removeDraft',
    async (request, thunkAPI) => {
        for (const order of request) {
            try {
                await axios.delete('/pedido/' + order.id);
            } catch (err) {
                thunkAPI.dispatch(
                    addNotification({
                        message: `${translate('columns.order')} ${
                            order.name
                        } ${translate('general.cannotBeDelete')}`,
                        title: translate('general.errorDelete'),
                        type: 'error',
                    }),
                );
            }
        }

        thunkAPI.dispatch(fetchDrafts());
        thunkAPI.dispatch(fetchScheduled());
    },
);

export const fetchDrafts = createAsyncThunk<Partial<DraftsResponse>[]>(
    'drafts/fetchDrafts',
    async (request, thunkAPI) => {
        try {
            const params = toQueryParameters({
                sort: 'dataPedido,desc',
                size: 999,
            });

            const response = await axios.get<
                PaginatedResponseApi<DraftResponseApi>
            >(`/pedido/list/rascunhos?${params}`);

            const { data, status } = response;

            if (status === 200) {
                const result = response.data.content.map((u) =>
                    Object.assign(new DraftResponseApi(), u),
                );

                return result.map((d) => d.toOrderState());
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            const { response } = e;
            const { data } = response;
            const { error } = data;
            const { message } = error;

            return thunkAPI.rejectWithValue(message);
        }
    },
);

export const fetchDraftsDashboard = createAsyncThunk<
    Paginated<Partial<DraftsResponse>>,
    CustomerRequest
>('drafts/fetchDraftsDashboard', async (request, thunkAPI) => {
    try {
        const state = thunkAPI.getState() as RootState;
        const { drafts } = state;
        const { request: oldRequest } = drafts;

        if (oldRequest) {
            const { company, filterValue, filterBy } = oldRequest;
            if (
                company != request.company ||
                filterValue != request.filterValue ||
                filterBy != request.filterBy
            )
                request.current = 1;
        }

        thunkAPI.dispatch(updateDraftRequest(request));

        const params = toQueryParameters({
            companhiaCodigo: request.company,
            filterValue: request.filterValue,
            filterType: request.filterBy?.toUpperCase()
                ? request.filterBy?.toUpperCase()
                : '',
            page: request.current - 1,
            size: request.pageSize < 10 ? 10 : request.pageSize,
            sort: 'dataPedido,desc',
        });

        const response = await axios.get<
            PaginatedResponseApi<DraftResponseApi>
        >(`/pedido/list/rascunhos?${params}`);

        const { data } = response;

        if (!data) {
            return {
                items: [],
                current: 0,
                total: 0,
                pageSize: 0,
            };
        } else {
            const result = response.data.content.map((u) =>
                Object.assign(new DraftResponseApi(), u),
            );
            console.debug(result);
            return {
                items: result.map((d) => d.toOrderState()),
                current: request.current,
                total: response.data.totalElements,
                pageSize: request.pageSize < 10 ? 10 : request.pageSize,
            };
        }
    } catch (e) {
        const { response } = e;
        const { data } = response;
        const { error } = data;
        const { message } = error;

        return thunkAPI.rejectWithValue(message);
    }
});

interface IDraftsState {
    drafts: DraftsResponse[];
    draftsDashboard: DraftsResponse[];
    sendedOrders: OrderRequest[];
    request: CustomerRequest;
}

const initialState: IDraftsState = {
    drafts: [],
    draftsDashboard: [],
    sendedOrders: [],
    request: {
        pageSize: 10,
        current: 1,
        company: '',
        filterBy: '',
        filterValue: '',
        categoria: ''
    },
};

const draftsSlice = createSlice({
    name: 'draftsSlice',
    initialState,
    reducers: {
        addDraft: (state, action: PayloadAction<DraftsResponse>) => {
            const { drafts } = state;
            const index = drafts.findIndex(
                (d) => d.draftRef === action.payload.draftRef,
            );

            if (index === -1) {
                state.drafts = [...state.drafts, action.payload];
            } else {
                state.drafts[index] = action.payload;
            }
            localStorage.setItem('drafts', JSON.stringify(state.drafts));
            return state;
        },
        addSendedOrders: (state, action: PayloadAction<OrderRequest>) => {
            state.sendedOrders = [...state.sendedOrders, action.payload];
            localStorage.setItem(
                'sended-orders',
                JSON.stringify(state.sendedOrders),
            );

            return state;
        },
        updateDraftRequest: (state, action: PayloadAction<CustomerRequest>) => {
            state.request = action.payload;

            return state;
        },
    },
    extraReducers: {
        [fetchDraftsDashboard.fulfilled.toString()]: (
            state,
            { payload }: PayloadAction<Paginated<DraftsResponse>>,
        ) => {
            console.debug(payload);
            state.draftsDashboard = [...payload.items];
            state.request = {
                ...state.request,
                total: payload.total,
                pageSize: payload.pageSize,
                current: payload.current,
            };
            return state;
        },
        [fetchDrafts.fulfilled.toString()]: (
            state,
            { payload }: PayloadAction<DraftsResponse[]>,
        ) => {
            state.drafts = payload;
            return state;
        },
    },
});

export const draftsSelector = (state: RootState): IDraftsState => state.drafts;

export const draftsValues = (state: RootState): DraftsTable[] =>
    state.drafts.drafts.map<DraftsTable>((d) => ({
        id: `${d.draftRef}`,
        condition: d.order.paymentCondition
            ? d.order.paymentCondition.description
            : translate('general.notSelected'),
        draftRef: d.order.date
            ? moment(d.order.date).format('DD/MM/YYYY')
            : translate('general.notInformed'),
        lastUpdate: moment(d.lastUpdate).format('DD/MM/YYYY'),
        name: d.order.customer.name,
        total: d.order.valorPedido,
        type: d.order.orderType.type.description,
        bonification: d.order.orderType.type.id.toString() !== 'S1',
        hasBonification: d.order.bonificationId
            ? translate('general.yes').toLocaleUpperCase()
            : translate('general.no').toLocaleUpperCase(),
        value: d.order.valorPedido,
        codigoMoeda: d.order.codigoMoeda,
    }));

export const draftsDashboardValues = (state: RootState): DraftsTable[] =>
    (state.drafts.draftsDashboard ?? [])?.map<DraftsTable>((d) => ({
        id: `${d.draftRef}`,
        condition: d.order.paymentCondition
            ? d.order.paymentCondition.description
            : translate('general.notSelected'),
        draftRef: d.order.date
            ? moment(d.order.date).format('DD/MM/YYYY')
            : translate('general.notInformed'),
        lastUpdate: moment(d.lastUpdate).format('DD/MM/YYYY'),
        name: d.order.customer.name,
        total: d.order.valorPedido,
        type: d.order.orderType.type.description,
        bonification: d.order.orderType.type.id.toString() !== 'S1',
        hasBonification: d.order.bonificationId
            ? translate('general.yes').toLocaleUpperCase()
            : translate('general.no').toLocaleUpperCase(),
        value: d.order.valorPedido,
        codigoMoeda: d.order.codigoMoeda,
    }));

export const sendedOrdersValues = (state: RootState): Partial<DraftsTable>[] =>
    state.drafts.sendedOrders.map<Partial<DraftsTable>>((d) => ({
        id: `${formatMoney(
            state.selectItems?.selectedMoeda?.simbolo, 
            d.availableItems?.reduce((c, b) => c + b.total, 0),
        )}`,
        condition: d.paymentCondition
            ? d.paymentCondition.description
            : translate('general.notSelected'),
        draftRef: d.date
            ? moment(d.date).format('DD/MM/YYYY')
            : translate('general.notInformed'),
        name: d.customer.name,
        total: d.availableItems?.reduce((c, b) => c + b.total, 0),
        type: d.orderType.type.description,
        codigoMoeda: d.codigoMoeda
    }));

export const { addDraft, updateDraftRequest, addSendedOrders } =
    draftsSlice.actions;

export default draftsSlice.reducer;
