import { Row } from 'antd';
import styled from 'styled-components';

export const MenuItem = styled(Row)`
    cursor: pointer;
    padding: 0.5rem;
    transition: all 0.2s ease-in-out;
    color: ${({ theme }) => theme.colors.darkBlue};
    align-items: center;
    column-gap: 10px;
    display: flex;
    flex-direction: column;
    span {
        font-weight: 400;
    }

    &.has-hover:hover {
        color: ${({ theme }) => theme.colors.white};
        background-color: ${({ theme }) => theme.colors.darkBlue};

        span {
            color: ${({ theme }) => theme.colors.white};
        }

        path {
            fill: ${({ theme }) => theme.colors.white};
        }
    }
`;
export const SubMenuBox = styled.div`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin-left: 15px;
    &.false {
        max-height: 0;
        transition: max-height 500ms ease-out;
    }
    &.open {
        max-height: 1000px;
        transition: max-height 2s ease-out;
    }
`;
export const FlexBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    svg {
        margin-right: 5px;
    }
`;
