import { RouterPath } from '@definitions/routes';
import { ApprovedOrdersReport } from '@pages/reports/approved-orders';
import { CommercialBonusesSalesReport } from '@pages/reports/commercial-bonuses-sales';
import { OrdersHistoryReport } from '@pages/reports/orders-history';
import { PendingOrdersReport } from '@pages/reports/pending-orders';
import { RequestedDiscountsApproved } from '@pages/reports/requested-discounts-approved';

const routes: RouterPath[] = [
    {
        path: '/reports/pending-orders',
        auth: true,
        render: () => <PendingOrdersReport />,
    },
    {
        path: '/reports/approved-orders',
        auth: true,
        render: () => <ApprovedOrdersReport />,
    },
    {
        path: '/reports/orders-history',
        auth: true,
        render: () => <OrdersHistoryReport />,
    },
    {
        path: '/reports/commercial-bonuses-sales',
        auth: true,
        render: () => <CommercialBonusesSalesReport />,
    },
    {
        path: '/reports/financial-discounts-approved',
        auth: true,
        render: () => <RequestedDiscountsApproved />,
    },
];

export default routes;
