import React, { useEffect } from 'react';
import { Page } from '@components/page';
import { LeftOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { Header } from './components/header';
import { Body } from './components/body';
import { CardTable } from '@components/table/styled';
import { Col } from 'antd';
import { fetchAllItems, fetchCampaigns } from '@redux/slices/campaign';
import {
    fetchAllAvailablePlatforms,
    fetchAvailablePaymentConditions,
    fetchCompanies,
} from '@redux/slices/order';
import { fetchDivisions } from '@redux/slices/divisions';
import { translate } from '@components/i18n';

export const Campaign: React.FC = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchCampaigns());
        dispatch(fetchDivisions());
        dispatch(fetchCompanies());
        dispatch(fetchAvailablePaymentConditions());
        dispatch(fetchAllAvailablePlatforms());
        dispatch(fetchAllItems());
    }, []);

    return (
        <Page
            title={translate('pages.campaign')}
            greyBackground
            onClickGoBack
            menuIcon={<LeftOutlined />}
        >
            <Col span={22} style={{ alignSelf: 'center' }}>
                <h2 style={{ marginTop: '15px' }}>
                    {translate('titlePages.campaign')}
                </h2>
                <CardTable>
                    <Header />
                    <Body />
                </CardTable>
            </Col>
        </Page>
    );
};
