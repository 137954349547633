import moment from 'moment';
import {
    CampaignRequest,
    CampaignRequestAPI,
    Conditions,
    Result,
} from './request';

export interface CampaignResponse extends CampaignRequest {
    id: number;
    nome: string;
    divisao: number;
    dataInicio: string;
    dataFim: string;
    exclusivo: boolean;
    tipo: string;
    condicoes: Conditions[];
    resultados: Result[];
    countPedidoRelacionado?: number;
    finalizado?: boolean;
}

export class CampaignResponseApi implements CampaignRequestAPI {
    id: number;
    nome: string;
    dataInicio: string;
    dataFim: string;
    exclusivo: boolean;
    tipo: string;
    divisao: number;
    condicoes: Conditions[];
    resultados: Result[];
    countPedidoRelacionado?: number;
    finalizado?: boolean;

    toCampaignResponse(): CampaignResponse {
        return {
            ...this,
            dataInicio: this.dataInicio
                ? moment(this.dataInicio).format('DD/MM/YYYY')
                : undefined,
            dataFim: this.dataFim
                ? moment(this.dataFim).format('DD/MM/YYYY')
                : undefined,
        };
    }
}

export interface ItemResponse {
    numero: string;
    descricao: string;
}
