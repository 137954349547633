import React, { memo } from 'react';
import { Col, Row } from 'antd';
import { OrderRequest } from '@models/order/items/request';
import { Dot } from '@styles/components/button';
import { formatMoney } from '@utils/money';
import {  SelectedMoeda } from '@models/order/moedas/response';

interface ISemaphoreProps {
    order: Partial<OrderRequest>;
    moeda: SelectedMoeda;
}

export const Semaphore: React.FC<ISemaphoreProps> = memo(({ order, moeda }) => {
    return (
        <Row align="middle" gutter={10}>
            <Col>{order && <>{formatMoney(moeda?.simbolo, order.valorPedido ?? 0)}</>}</Col>
            <Col>
                {order &&
                    order?.orderType?.type?.id !== 'V2' &&
                    order.semaforoPedido && (
                        <Dot background={order.semaforoPedido} />
                    )}
            </Col>
        </Row>
    );
});
