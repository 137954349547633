import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from '@axios';
import { RootState } from '@redux/reducers';
import { ApprovalFilterRequest } from '@models/approval/request';
import { Paginated } from '@models/paginated';
import { toQueryParameters } from 'src/extensions/object';
import { PaginatedResponseApi } from '@models/order/items/response';
import { DraftsResponse } from './drafts';
import { DraftResponseApi } from '@models/order/drafs/response';
import moment from 'moment';
import { translate } from '@components/i18n';
import { formatMoney } from '@utils/money';

type ApprovalResponse = DraftsResponse;

export interface ApprovalTable {
    id: string;
    division: string;
    type: string;
    representative: string;
    customerName: string;
    customerCode: string;
    date: string;
    total: string;
}

export const fetchDashboardApprovals = createAsyncThunk<
    Paginated<ApprovalResponse>,
    ApprovalFilterRequest
>('select-approval/fetchDashboardApprovals', async (req, thunkAPI) => {
    try {
        const state = thunkAPI.getState() as RootState;
        const { approval } = state;
        const { request: oldRequest } = approval;
        const {
            waitingApproval,
            pending,
            aprovadorId,
            filterBy,
            filterValue,
            comparisonType,
            dataInicio,
            dataFim,
        } = oldRequest;

        if (
            pending != req.pending ||
            waitingApproval != req.waitingApproval ||
            aprovadorId != req.aprovadorId ||
            filterBy != req.filterBy ||
            filterValue != req.filterValue ||
            comparisonType != req.comparisonType ||
            dataInicio != req.dataInicio ||
            dataFim != req.dataFim
        )
            req.current = 1;

        thunkAPI.dispatch(updateApprovalRequest(req));

        const params = toQueryParameters({
            pendenteAprovacao: req.pending ? true : '',
            aguardandoAprovacao: req.waitingApproval ? true : '',
            aprovadorId: req.aprovadorId,
            comparisonType: req.comparisonType,
            page: req.current - 1,
            size: req.pageSize < 10 ? 10 : req.pageSize,
            sort: 'dataPedido,desc',
            dataInicio: req.dataInicio,
            dataFim: req.dataFim,
            filterValue: req.filterValue,
            filterType: req.filterBy?.toUpperCase()
                ? req.filterBy?.toUpperCase()
                : '',
        });

        const response = await axios.get<
            PaginatedResponseApi<DraftResponseApi>
        >(
            `/pedido/list/aprovacao?${params}&sort=dataProgramada,desc&sort=id,desc`,
        );

        const { data: dataResponse, status: statusResponse } = response;

        if (!dataResponse) {
            return {
                items: [],
                current: 0,
                total: 0,
                pageSize: 0,
            };
        } else {
            const result = dataResponse.content.map((u) =>
                Object.assign(new DraftResponseApi(), u),
            );
            console.debug(result, statusResponse);
            if (statusResponse === 200) {
                return {
                    items: result.map(
                        (u) => u.toOrderState() as ApprovalResponse,
                    ),
                    current: req.current,
                    total: response.data.totalElements,
                    pageSize: response.data.numberOfElements,
                };
            } else {
                return thunkAPI.rejectWithValue('');
            }
        }
    } catch (e) {
        const { response } = e;
        const { data } = response;
        const { error } = data;
        const { message } = error;

        return thunkAPI.rejectWithValue(message);
    }
});

export const fetchApprovals = createAsyncThunk<
    Paginated<ApprovalResponse>,
    ApprovalFilterRequest
>('select-approval/fetchApprovals', async (req, thunkAPI) => {
    try {
        const state = thunkAPI.getState() as RootState;
        const { approval } = state;
        const { request: oldRequest } = approval;
        const { waitingApproval, pending } = oldRequest;

        if (pending != req.pending || waitingApproval != req.waitingApproval)
            req.current = 1;

        thunkAPI.dispatch(updateApprovalRequest(req));

        const params = toQueryParameters({
            pendenteAprovacao: req.pending,
            aguardandoAprovacao: req.waitingApproval,
        });

        const response = await axios.get<
            PaginatedResponseApi<DraftResponseApi>
        >(`/pedido/list/aprovacao?${params}`);

        const { data: dataResponse, status: statusResponse } = response;

        if (!dataResponse) {
            return {
                items: [],
                current: 0,
                total: 0,
                pageSize: 0,
            };
        } else {
            const result = dataResponse.content.map((u) =>
                Object.assign(new DraftResponseApi(), u),
            );
            console.debug(result, statusResponse);
            if (statusResponse === 200) {
                return {
                    items: result.map(
                        (u) => u.toOrderState() as ApprovalResponse,
                    ),
                    current: req.current,
                    total: response.data.totalElements,
                    pageSize: response.data.numberOfElements,
                };
            } else {
                return thunkAPI.rejectWithValue('');
            }
        }
    } catch (e) {
        const { response } = e;
        const { data } = response;
        const { error } = data;
        const { message } = error;

        return thunkAPI.rejectWithValue(message);
    }
});

interface IApprovalSelectorState {
    availableApprovals: ApprovalResponse[];
    availableApprovalsDashboard: ApprovalResponse[];
    request: ApprovalFilterRequest;
    isFetching: boolean;
    isSuccess: boolean;
    isError: boolean;
    errorMessage: string;
}

const initialState: IApprovalSelectorState = {
    availableApprovals: [],
    availableApprovalsDashboard: [],
    request: {
        pageSize: 10,
        current: 1,
        total: 0,
    },
    isFetching: true,
    isSuccess: false,
    isError: false,
    errorMessage: '',
};

const selectApprovalSlice = createSlice({
    name: 'selectApprovalSlice',
    initialState,
    reducers: {
        updateApprovalRequest: (
            state,
            action: PayloadAction<ApprovalFilterRequest>,
        ) => {
            state.request = action.payload;

            return state;
        },
        resetSelectApprovalState: (state) => {
            state = { ...initialState };
            return state;
        },
    },
    extraReducers: {
        [fetchDashboardApprovals.fulfilled.toString()]: (
            state,
            { payload }: PayloadAction<Paginated<ApprovalResponse>>,
        ) => {
            state.availableApprovalsDashboard = [...payload.items];
            state.request = {
                ...state.request,
                total: payload.total,
                pageSize: payload.pageSize,
                current: payload.current,
            };
            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [fetchDashboardApprovals.rejected.toString()]: (state, action) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = action.payload;
        },
        [fetchDashboardApprovals.pending.toString()]: (state) => {
            state.isFetching = true;
            state.isError = false;
        },
        [fetchApprovals.fulfilled.toString()]: (
            state,
            { payload }: PayloadAction<Paginated<ApprovalResponse>>,
        ) => {
            state.availableApprovals = [...payload.items];
            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [fetchApprovals.rejected.toString()]: (state, action) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = action.payload;
        },
        [fetchApprovals.pending.toString()]: (state) => {
            state.isFetching = true;
            state.isError = false;
        },
    },
});

export const approvalSelector = (state: RootState): IApprovalSelectorState =>
    state.approval;

export const { updateApprovalRequest, resetSelectApprovalState } =
    selectApprovalSlice.actions;

export const approvalValues = (state: RootState): ApprovalTable[] =>
    state.approval.availableApprovals.map<ApprovalTable>((d) => ({
        id: `${d.draftRef}`,
        date: moment(d.order.date).format('DD/MM/YYYY'),
        representative: d.order.userName,
        total: formatMoney(state.selectItems?.selectedMoeda?.simbolo, d.order.valorPedido),
        type: d.order.orderType.type.description,
        customerCode: `${d.order.customer.code}`,
        customerName: d.order.customer.name,
        division: d.order.division,
        hasBonification: d.order.bonificationId
            ? translate('general.yes').toLocaleUpperCase()
            : translate('general.no').toLocaleUpperCase(),
        pendingWith: d.order.aprovacaoPendenteUsuario,
    }));

export const approvalValuesDashboard = (state: RootState): ApprovalTable[] =>
    (state.approval.availableApprovalsDashboard ?? []).map<ApprovalTable>(
        (d) => ({
            id: `${d.draftRef}`,
            date: moment(d.order.date).format('DD/MM/YYYY'),
            representative: d.order.userName,
            total: formatMoney(state.selectItems?.selectedMoeda?.simbolo, d.order.valorPedido),
            type: d.order.orderType.type.description,
            customerCode: `${d.order.customer.code}`,
            customerName: d.order.customer.name,
            customerCity: `${d.order.customer.city} - ${d.order.customer.state}`,
            division: d.order.division,
            hasBonification: d.order.bonificationId
                ? translate('general.yes').toLocaleUpperCase()
                : translate('general.no').toLocaleUpperCase(),
            pendingWith: d.order.aprovacaoPendenteUsuario,
        }),
    );

export default selectApprovalSlice.reducer;
