import React, { memo } from 'react';

import { Logo } from '@components';

import { PageHeader } from './styled';
import { Menu } from './components/menu';
import { Actions } from './components/actions';
import { Col, Row } from 'antd';
import { useHistory } from 'react-router-dom';

export interface IHeaderProps {
    title?: string;
    onMenuClick?: () => void;
    menuIcon?: React.ReactNode;
    onClickGoBack?: boolean;
}

export const Header: React.FC<IHeaderProps> = memo(
    ({ menuIcon, onMenuClick, title, onClickGoBack = false }) => {
        const history = useHistory();

        const goToHome = (): void => {
            history.push('/');
        };

        const goBack = (): void => history.goBack();

        return (
            <PageHeader>
                <Menu
                    menuIcon={menuIcon}
                    onMenuClick={onClickGoBack ? goBack : onMenuClick}
                    title={title}
                />
                <Col span={6}>
                    <Row justify="center">
                        <div onClick={goToHome} style={{ cursor: 'pointer' }}>
                            <Logo size={80} />
                        </div>
                    </Row>
                </Col>
                <Actions />
            </PageHeader>
        );
    },
);
