export default {
    translations: {
        pages: {
            home: 'Pedido Electrónico',
            users: 'Usuarios',
            scheduledOrders: 'Pedidos Programados',
            financialDiscountApproved: 'Descuentos financieros aprobados',
            pendingOrders: 'Ordenes Pendientes',
            historyOfOrders: 'Historial de Pedidos',
            comercialBonus: 'Bonos Comerciales X Ventas',
            approvedOrders: 'Pedidos aprobados',
            sendOrder: 'Enviar pedidos',
            newOrder: 'Nuevo Pedido',
            newOrderItems: 'Nuevo pedido: Artículos',
            searchCliente: 'Buscar cliente',
            newOrderBonificatoinMarketing:
                'Nuevo Pedido: Bonificación de Marketing',
            draft: 'Borradores',
            newOrderBonification: 'Nuevo Pedido - Bonificación',
            minOrder: 'Pedido mínimo',
            orderSelection: 'Selección de pedidos',
            selectClient: 'Buscar cliente',
            historyOfDiscountSolicitations:
                'Historial de solicitudes de descuento',
            approvalsOfFinancialDiscount:
                'Aprobaciones de descuentos financieros',
            approvalDiscount: 'Aprobación de descuento',
            discountSolicitations: 'Solicitud de descuento',
            delegation: 'Delegación',
            settings: 'Ajustes',
            campaign: 'Campaña',
            login: 'Cuenta de acceso',
            orders: 'Pedidos',
            orderApprovals: 'Aprobación del pedido',
            marketingApprovals: 'Aprobadores de marketing',
            approvalGroup: 'Grupos de aprobación',
            items: 'Elementos',
            deadline: 'Plazo',
            itemDeadline: 'Artículo X Plazos',
            cost: 'Costo',
            semaphore: 'Semáforo',
            route: 'Rutas',
        },
        titlePages: {
            home: 'Pedido Electrónico',
            users: 'Usuarios',
            scheduledOrders: 'Pedidos Programados',
            financialDiscountApproved: 'Descuentos financieros aprobados',
            pendingOrders: 'Ordenes Pendientes',
            historyOfOrders: 'Historial de Pedidos',
            comercialBonus: 'Bonos Comerciales X Ventas',
            approvedOrders: 'Pedidos aprobados',
            sendOrder: 'Enviar pedidos',
            newOrder: 'Nuevo Pedido',
            newOrderItems: 'Nuevo pedido: Artículos',
            searchCliente: 'Seleccionar/Buscar un cliente',
            draft: 'Borradores',
            minOrder: 'Pedido mínimo',
            orderSelection: 'Selección de pedidos',
            selectClient: 'Seleccionar/Buscar un cliente',
            historyOfDiscountSolicitations:
                'Historial de descuentos financieros',
            approvalsOfFinancialDiscount:
                'Aprobaciones de descuentos financieros',
            delegation: 'Delegación',
            settings: 'Ajustes',
            campaign: 'Campaña',
            loginOrder: 'Solicitud',
            loginEletronic: 'Electrónico',
            orders: 'Pedidos',
            marketingApprovals: 'Aprobadores de marketing',
            approvalGroup: 'Grupos de aprobación',
            items: 'Elementos',
            deadline: 'Plazo',
            itemDeadline: 'Artículo X Plazos',
            cost: 'Costo',
            semaphore: 'Semáforo',
            route: 'Rutas',
        },
        home: {
            hello: 'Hola',
            welcome: 'Bienvenido (a)!',
            cards: {
                new: 'Nuevo',
                order: 'Pedido',
                orders: 'Pedidos',
                draft: 'Borradores',
                scheduled: 'Programado',
                approvals: 'Aprobaciones',
                reports: 'Informes',
                historyOf: 'Historial de',
                newSolicitationOf: 'Nuevo pedido de',
                financialDiscount: 'Descuento financiero',
                approvalsOf: 'Aprobaciones de',
            },
        },
        sideMenu: {
            start: 'Comienzo',
            orders: {
                self: 'Pedidos',
                newOrder: 'Nuevo pedido',
                draftOrders: 'Borradores de pedidos',
                scheduledOrders: 'Pedidos programados',
                historyOfOrders: 'Historial de pedidos',
                scheduled: 'Programado',
            },
            approvals: {
                self: 'Aprobaciones',
                orders: 'Pedidos',
                financialDiscounts: 'Descuento financiero',
            },
            financialDiscounts: {
                self: 'Descuentos financieros',
                discountSolicitation: 'Solicitud de descuento',
                historyOfDiscount: 'Historial de descuentos',
            },
            registrations: {
                self: 'Registros',
                users: 'Usuarios',
                approvalGroups: 'Grupos de aprobación',
                marketingApproval: 'Aprobadores de marketing',
                campaigns: 'Campañas',
                minOrder: 'Pedido mínimo',
                items: 'Elementos',
                deadline: 'Plazo',
                itemDeadline: 'Artículo X Plazos',
                cost: 'Costo',
                semaphore: 'Semáforo',
                route: 'Rutas',
            },
            delegation: 'Delegación',
            settings: 'Ajustes',
            reports: {
                self: 'Informes',
                pendingOrders: 'Ordenes pendientes',
                approvedOrders: 'Pedidos aprobados',
                historyOfOrders: 'Historial de pedidos',
                comercialBonus: 'Bonos Comerciales X Ventas',
                financialDiscountsApproved: 'Descuentos financieros aprobados',
            },
        },
        forms: {
            rules: {
                login: 'Es obligatorio ingresar su nombre de usuario',
                password: 'Es obligatorio introducir tu contraseña',
                name: 'Requerido para ingresar el nombre',
                fiveCaracteres: 'Por favor ingrese al menos 5 caracteres',
                division: 'Requerido para elegir división',
                roles: 'Obligatorio elegir al menos una ocupación',
                email: 'Requerido para ingresar correo electrónico',
                validEmail:
                    'Introduzca una dirección de correo electrónico válida',
                len10: 'Campo con un máximo de 10 dígitos',
                limit: 'Requerido para ingresar el límite',
                existLogin: 'Ya existe un usuario con este login',
                selectOption: 'Necesario para elegir una opción',
                selectUserCopy:
                    'Obligatorio elegir el usuario para copiar los datos',
                transportType: 'Obligatorio elegir el tipo de transporte',
                value: 'Requerido para ingresar el valor',
                delegate: 'Obligatorio elegir delegado',
                startDate: 'Obligatorio para elegir la fecha de inicio',
                endDate: 'Obligatorio elegir la fecha de finalización',
                startDateBeforeToday:
                    'La fecha de inicio no puede ser anterior a la fecha de hoy',
                endDateCannotBeBeforeStart:
                    'La fecha de finalización no puede ser anterior a la fecha de inicio.',
                startDateCannotBeAfterEnd: "La fecha de inicio no puede ser después de la fecha final",
                delegateReason: 'Requerido para elegir el motivo',
                numberItem: 'Requerido para ingresar el número de artículo',
                campainName: 'Requerido para ingresar el nombre de la campaña',
                selectedCampain: 'Obligatorio para elegir el tipo de campaña',
                field: 'Por favor seleccione un campo',
                operator: 'Obligatorio elegir el operador',
                chooseValue: 'Requerido para elegir valor',
                qtd: 'Por favor ingrese la cantidad',
                obg: 'Campo obligatorio',
                approvalGroupName:
                    'Requerido para ingresar el nombre del grupo de aprobación',
                chooseOrderType: 'Obligatorio para elegir el tipo de orden',
            },
            labels: {
                email: 'Correo electrónico',
                copyEmail: 'Copiar correo electrónico',
                login: 'Acceso',
                password: 'Clave',
                rememberAccess: 'Recordar acceso',
                recoveryLogin:
                    'Ingrese su nombre de usuario para recuperar la contraseña',
                fullName: 'Nombre completo',
                division: 'División',
                roles: 'Ocupación',
                userLimitBonification: 'Límite de usuario de bonificación',
                userLimit: 'Límite de usuarios',
                approvalGroupOfSales: 'Grupo de aprobación de ventas',
                approvalGroupOfDiscounts: 'Grupo de aprobación de descuentos',
                marketingApprover: 'Aprobador de marketing',
                specifyItems: 'Artículos específicos:',
                jdeCode: 'Código JDE',
                ignoreDailyEmailScheduledOrders:
                    'Ignorar el correo electrónico diario de pedidos programados',
                ignoreDailyEmailApprovals:
                    'Ignorar correo electrónico de aprobaciones diarias',
                lineManager: '¿Gerente de línea?',
                active: 'Activo',
                line: 'Línea',
                showDivisionClients: 'Ver clientes de la división',
                chooseUserCopy: 'Elija el usuario para copiar los datos',
                searchFor: 'Buscar',
                searchType: 'Tipo',
                cia: 'CIA',
                type: 'Grupo de cliente',
                month: 'Mes',
                year: 'Año',
                date: 'Fecha',
                status: 'Estado',
                scheduleOrder: 'Programar orden',
                orderType: 'Tipo de orden',
                originPlatform: 'Almacen',
                moneyType: 'Moneda',
                client: 'Cliente',
                cnpj: 'CUIT',
                sendOrderWithNextSale:
                    'Enviar pedido con el próximo pedido de venta',
                sendCopyFor: 'Enviar copia a',
                observation: 'Observación',
                selectDiscountValue: 'Seleccione el monto del descuento',
                paymentCondition: 'Condición de pago',
                buyOrder: 'Orden de compra',
                deliveryDate: 'Fecha de entrega',
                scheduledDate: 'Fecha programada',
                transportCode: 'Código de tipo de transporte',
                transportType: 'Tipo de transporte',
                validation: 'Validación',
                clientType: 'Tipos de clientes',
                origin: 'Origen',
                destiny: 'Destino',
                minValue: 'Valor mínimo',
                totalOrdersValues: 'Cantidad total ordenada',
                totalValueWithDiscount: 'Importe total con descuento',
                valueDiscount: 'Importe de descuento',
                financialDiscount: 'Descuento financiero',
                someObservation: '¿Alguna observación?',
                selection: 'Selección',
                filterBySituation: 'Filtrar por estado',
                whyYouAreRefusingThisOrder:
                    'Ingrese por qué está rechazando esta solicitud',
                results: 'Resultados',
                field: 'Campo',
                numberItem: 'Número de artículo',
                operator: 'Operador',
                value: 'Valor',
                qtd: 'La cantidad',
                exclusive: 'Exclusivo',
                presentation: 'Apresentação',
                name: 'Nombre',
                color: 'Color',
                percent: 'Porcentaje',
                compkit: 'CompKit',
                controlled: 'Revisado',
                agreement100: 'Acuerdo 100',
                etrade: 'ETrade',
                ipi: 'IPI',
                kit: 'Kit',
                limited: 'Limitado',
                multiply: 'Múltiple',
                ipiValue: 'Valor IPI',
                number: 'Número',
                branch: 'Subsidiaria',
                icmsCredit: 'Crédito ICMS',
                code: 'Código',
                description: 'Descripción',
                minOrder: 'Pedido mínimo',
                branchCode: 'Código de sucursal',
                campainCode: 'Código de campaña',
                state: 'Provincia',
                reportPermission: "Permiso de informes",
                category: 'Categoría'
            },
            placeholders: {
                login: 'Ingrese su inicio de sesión',
                password: 'Ingresa tu contraseña',
                photo: 'Fotografía',
                searchGroup: 'Buscar por nombre, correo electrónico...',
                searchGroupDivision: 'Buscar por nombre, división...',
                line: 'Seleccione la línea',
                chooseFilter: 'Elige un filtro',
                search: 'Búsqueda',
                startDate: 'Fecha de inicio',
                endDate: 'Fecha final',
                chooseDate: 'Elige una fecha (opcional)',
                chooseOption: 'Escoge una opción',
                email: 'Correo electrónico',
                writeTheData: 'Informar los datos',
                chooseTheDate: 'Elige la fecha',
                comment: 'Escriba su comentario',
                letReason: 'Introduce el motivo. Mínimo: 5 caracteres',
                searchForReason: 'Buscar por motivo',
                writeValue: 'Introduce el valor',
                chooseApprover: 'Elegir un aprobador',
                searchByName: 'Buscar por nombre',
                selectType: 'Seleccione un tipo',
                selectApprover: 'Seleccione un aprobador',
                selectPermission: 'Seleccione un permiso',
            },
            buttons: {
                access: 'Acceso',
                forgotPasswordQuestion: 'Olvido la contraseña?',
                copyUser: 'Copiar de otro usuario',
                addNew: 'Agregar nuevo',
                addNewF: 'Añadir nuevo',
                search: 'Búsqueda',
                changeClient: 'Cambiar de cliente',
                continue: 'Proceder',
                apply: 'Aplicar',
                addValidation: 'Añadir validación',
                requestDiscount: 'Solicitar descuento',
                discountApprove: 'Aprobar descuento',
                toApprove: 'Aprobar',
                toRefuse: 'Rechazar',
                update: 'Actualizar',
                endCampain: 'Finalizar campaña(s)',
                add: 'Agregar',
                addCondition: 'Añadir condición',
                copyOrder: 'Copiar Pedido',
                toRepprove: 'Rechazar',
                addApprover: 'Agregar aprobador',
                campaigns: 'Campañas',
            },
        },
        general: {
            recoveryAccess: 'Recuperar el acceso',
            verifyingAccess: 'Comprobando acceso...',
            registeringUser: 'Registrando usuario...',
            registerUser: 'Añadir nuevo usuario',
            updatingUser: 'Actualizando usuario...',
            editUser: 'eEitar usuario',
            adm: 'Administrador',
            approver: 'Aprobador',
            approved: 'Aprobado',
            pending: 'Pendiente',
            reproved: 'Rechazado',
            representer: 'Representante',
            billing: 'Facturación',
            controllership: 'Controladuría',
            financial: 'Financiero',
            all: 'Todos',
            delete: 'Borrar',
            export: 'Exportar',
            messageDelete: '¿Estas seguro que quieres borrarlo?',
            yes: 'Sí',
            no: 'No',
            ok: 'OK',
            cancel: 'Cancelar',
            loginAs: 'Iniciar sesión como',
            errorT: '¡Error!',
            logoutLogin:
                'Se cerrará la sesión y se iniciará sesión como este usuario. Estás seguro de que quieres continuar?',
            perPage: 'por página',
            totalItems: 'Articulos totales:',
            newOrder: 'Nuevo pedido',
            orderOnHold: 'Pedidos en espera',
            yourOrdersZero: 'Tienes 0 pedidos nuevos',
            yourOrdersTwenty: 'Tienes 20 pedidos en espera',
            editPersonalData: 'Editar datos personales',
            myData: 'Mis datos',
            division: 'División',
            logout: 'Salir',
            send: 'Enviar',
            login: 'Acceso',
            copyright: 'Copyright © 2022. Todos los derechos reservados',
            sendOrderWithoutBonification:
                'Está enviando pedidos sin reembolso. ¿Estás seguro de que quieres enviar?',
            orderSelectedModalFirstPart: `Pedidos seleccionados (y
                bonificaciones eventualmente
                relacionado)`,
            orderSelectedModalSecondPart:
                'será excluido\n definitivamente. Quieres proceder?',
            sendingOrders: 'Enviando pedidos...',
            resendedEmail: '¡Reenvío de correo electrónico!',
            resendedEmailError:
                '¡Hubo un error al enviar el correo electrónico del pedido!',
            success: '¡Éxito!',
            error: 'Ocurrió un error, inténtalo de nuevo más tarde.',
            filialAvailableError:
                'No hay sucursales disponibles para este tipo de pedido.',
            chooseApprover: 'elegir aprobador',
            wantChooseApprover: '¿Quiere elegir el aprobador?',
            advance: 'Continuar',
            ipiTax: 'En su caso, los artículos con IPI tendrán el valor de\n           impuesto agregado al subtotal.',
            activatedEarlyDiscount: 'Descuento por reserva anticipada activado',
            saleOrder: 'Orden de venta',
            cannotBeEdited: 'Resultado: no se puede editar',
            activeCampains: 'Campañas activas',
            orderAmout: 'TOTAL PEDIDO',
            emptyList: 'No se encontraron registros',
            cancelSorting: 'Haga clic para cancelar el pedido',
            ascSort: 'clic para ordenar ascendente',
            descSort: 'haga clic para ordenar de forma descendente',
            colapseLine: 'Línea de colapso',
            expandLine: 'Expandir fila',
            sort: 'Ordenar',
            selectAllData: 'Seleccionar todos los datos',
            filterTitle: 'Menú de filtro',
            filterConfirm: 'OK',
            filterReset: 'Reiniciar',
            filterEmptyText: 'Sin filtros',
            selectAll: 'Seleccione la página actual',
            selectInvert: 'Invertir selección',
            sendingMarketingBonitication: 'Enviando bono de marketing...',
            wantCreateBonificationForThisOrder:
                '¿Quisieras crear una bonificacion para este pedido?',
            comercialBonification: 'Bono comercial',
            sendingOrder: 'Enviando pedido...',
            savingDraft: 'Guardando borrador...',
            edition: 'edición',
            creation: 'creación',
            wantExitOf: 'Estás seguro que quieres irte',
            toOrder: '¿De la orden?',
            toprogramming: 'del horario?',
            sendOrderWithNextSale:
                'Enviar pedido con el próximo pedido de venta',
            noDetectedApprover: 'Sin aprobador\ndetectado por esto\nsolicitud',
            approvers: 'Aprobadores',
            saveDraft: 'Guardar borrador',
            editBonitication: 'Editar bono',
            wantToCancel: '¿Quieres cancelar la',
            wantToCancelBonificationAndRetorn:
                '¿Realmente desea cancelar el bono y volver a la pantalla de pedido de venta?',
            verifyingOtherOrders: 'Consultando otros pedidos',
            maybeWantBonify: 'Que tal vez quieras recompensar',
            noOtherOrderFind: 'No se encontraron otros pedidos',
            wantToAddOtherOrderToBonification:
                '¿Quieres añadir más pedidos de bonificación?',
            deliveryClient: 'Entrega al cliente',
            changePrice: 'Cambio de precio',
            loading: 'Cargando...',
            newPrice: 'Nuevo precio',
            in: 'en',
            lasPriceWas: 'El último precio cobrado fue',
            noHistoryForThisItem: 'No se encontró historial para este artículo',
            newValue: 'nuevo valor',
            actualOrder: 'Orden actual',
            suframaDiscount: 'Descuento Suframa',
            tradeValue: 'Valor comercial',
            suggestionNotFound:
                'No se puede encontrar una sugerencia de plazo de pago, elija una antes de guardar el pedido',
            addNewMinOrder: 'Añadir nuevo pedido mínimo',
            editMinOrder: 'Editar pedido mínimo',
            registeringNewMinOrder: 'Agregando un nuevo pedido mínimo...',
            updatingNewMinOrder: 'Actualizando pedido mínimo...',
            minOrderRemovedSuccess: 'Pedido mínimo eliminado con éxito',
            validations: 'Validaciones',
            exceptions: 'Excepciones',
            existValidationsWithThisConditions:
                'Ya existe una validación con estas condiciones',
            requestDiscount: 'Solicitar descuento',
            orders: 'peticiones)',
            selectingDiscountYouSure:
                'Estás seleccionando pedidos para descuento. Estás seguro de que quieres continuar?',
            discount: 'Descuento',
            order: 'Solicitud',
            activeCampain: 'Campañas\n           activo',
            notInformed: 'No informado',
            wating: 'esperando',
            comentary: 'Comentario',
            noComment: 'No hay comentarios',
            removeComment: 'Quitar comentario',
            lostCommentYouSure:
                'Te perderás este comentario. ¿Estas seguro que quieres borrarlo?',
            goBackList: 'Volver a la lista',
            whatRefuse: '¿Cuál es el motivo de la negativa?',
            letObservation: 'Si quieres deja un comentario',
            ordersT: 'Peticiones',
            iAndMyDelegations: 'Yo y mis delegaciones',
            i: 'Yo',
            myPendingApprovals: 'El mío lo aprueba. sobresaliente',
            waitingApproval: 'Espere aprobadores anteriores',
            addNewDelegation: 'Añadir nueva Delegación',
            updatingApprovalGroup: 'Actualizando grupo de aprobación...',
            registeringApprovalGroup: 'Registrando grupo de aprobación...',
            editDelegation: 'Editar delegación',
            existDelegation: 'Ya hay delegación',
            selectedPeriod: 'para el periodo elegido',
            updatingParameter: 'Actualizando parámetro...',
            editParameter: 'Editar parámetro',
            addNewCampain: 'Añadir nueva campaña',
            editCampain: 'Editar campaña',
            endCampain: '¡Campaña(s) terminada(s)!',
            errorOnEndCampain: 'Ocurrió un error al finalizar las campañas',
            activeCampainsSelected: 'Las campañas activas seleccionadas',
            activeCampainsSelected2Part:
                'estara cerrado\ninmediatamente. Quieres proceder?',
            updatingCampain: 'Actualizando campaña...',
            registeringNewCampain: 'Agregando nueva campaña...',
            existCampainWithThisName: 'Ya existe una campaña con este nombre',
            campainCondition: 'Condiciones de la campaña',
            orderType: 'Tipo de orden',
            paymentCondition: 'Condición de pago',
            commentOfItem: 'Comentarios del artículo',
            query: 'Consulta',
            nextOrder: 'Siguiente orden',
            actualSale: 'Venta actual',
            weightedDiscount: 'Descuento ponderado',
            copyingOrder: 'Orden de copia...',
            commentHistory: 'Historial de comentarios',
            commentSave: '¡Comentario guardado con éxito!',
            errorComment: 'Ocurrió un error al agregar el comentario.',
            deleteComment: '¡Comentario eliminado con éxito!',
            errorRefuseOrder: '¡Hubo un error al denegar la solicitud!',
            refusedOrder: '¡Solicitud rechazada!',
            acceptOrder: 'Pedido aceptado!',
            errorAcceptOrder: '¡Hubo un error al aceptar el pedido!',
            solicitationDiscount: '¡Descuento solicitado!',
            errorSolicitationDiscount:
                '¡Hubo un error al solicitar el descuento de los pedidos!',
            addNewGroup: 'Añadir nuevo grupo',
            editMarketingApprover: 'Editar aprobador de marketing',
            marketingBonification: 'Bono de mercadeo',
            comercialBonificationSale: 'Venta / Bono Comercial',
            financialDiscounts: 'Descuentos Financieros',
            editApprovalGroup: 'Editar grupo de aprobación',
            existApprovalGroupWithThisName:
                'Ya existe un grupo de aprobación con este nombre',
            errorListApprovalGroup:
                'Hubo un error al enumerar los grupos de aprobación',
            approvalGroupRegistered: 'Grupo de aprobación registrado con éxito',
            errorRegisteringUser: 'Ocurrió un error al registrar al usuario',
            groupUpdated: 'Grupo actualizado correctamente',
            approvalGroupUpdated: 'Grupo de aprobación actualizado con éxito',
            errorChangeLogin: 'Ocurrió un error al cambiar el inicio de sesión',
            userOrPasswordError:
                'El nombre de usuario o la contraseña no son válidos',
            errorRegisteringCampain: 'Ocurrió un error al registrar la campaña',
            campainRemoved: 'Campaña eliminada con éxito',
            campainUpdated: 'Campaña actualizada con éxito',
            errorUpdatingCampain: 'Ocurrió un error al actualizar la campaña.',
            campainRegistered: 'Campaña registrada con éxito',
            parameterUpdated: 'Parámetro actualizado correctamente',
            errorUpdatingParameter:
                'Ocurrió un error al actualizar el parámetro',
            errorListingParameter: 'Hubo un error al enumerar los parámetros.',
            userUpdated: 'Usuario actualizado correctamente',
            erroListingUsers: 'Hubo un error en la lista de usuarios.',
            errorListingMinOrders:
                'Hubo un error al enumerar los pedidos mínimos',
            minOrderRegistered: 'Pedido mínimo registrado con éxito',
            minOrderUpdated: 'Pedido mínimo actualizado con éxito',
            bonificationOrderUpdated:
                'Solicitud de bonificación actualizada con éxito',
            cannotBeDelete: 'no se puede borrar por el momento',
            errorDelete: 'Eliminación fallida',
            notSelected: 'No seleccionado',
            reportFinacialDiscountApproveds:
                'Informe_descuentos_financieros_aprobados',
            reportScheduledOrders: 'Informe_Pedidos_Programados',
            orderCreatedAndDraft: 'Pedido creado y guardado en borradores',
            orderUpdatedAndDraft: 'Pedido actualizado y guardado en borradores',
            orderSended: 'Pedido enviado con éxito',
            minValueTransport:
                'No se ha alcanzado el valor mínimo de uno o más tipos de transporte:',
            minValueOf: 'Valor mínimo de',
            notReached: 'no alcanzado.',
            registeredUpdated: 'Usuario registrado con éxito',
            sendedEmailWithNewPassword:
                'Se ha enviado un correo electrónico con la nueva contraseña a la dirección de correo electrónico registrada.',
            userNotFound: 'Usuario no encontrado',
            noHasNecessaryItems: 'No cuenta con los elementos necesarios',
            campainsAvailableToOrder: 'Campañas disponibles para este pedido',
            reportPendingOrders: 'Informe_Pedidos_Pendientes',
            reportApprovedOrders: 'Informe_Pedidos_Aprobados',
            reportComercialSalesBonification:
                'Report_Bonificacoes_Comerciais_Sales',
            reportOrderHistory: 'Informe_Historial_Pedidos',
            erroListingItems: 'Hubo un error al enumerar los artículos.',
            itemUpdated: 'Artículo actualizado con éxito',
            registerItem: 'Agregar ítem nuevo',
            updatingItem: 'Actualizando elemento...',
            registeringItem: 'Agregando elemento...',
            editItem: 'Editar elemento',
            registered: 'Registrado correctamente',
            updated: 'Actualizado exitosamente',
            noBaseOfPrice: "Item sin precio base",
            discountApprove: 'Aprobar descuento',
            discountRepprove: 'Rechazar descuento',
            integrated: 'Integrado',
            billed: 'Facturado',
            delivered: 'Entregado',
            onlyMyOrders: 'Accede solo a tus pedidos',
            onlyMyDivision: 'Acceso solo a las solicitudes de BU para las que se registró el usuario',
            totalAccess: 'Acceso completo',
            bonificationOrderSuccess: 'Bonificacion creada con exito',
            youChoosedAExclusiveCampain: 'Ha seleccionado una campaña única, otras campañas seleccionadas no estarán seleccionadas. ¿Está seguro?',
            choosedExclusiveCampain: 'Campaña exclusiva seleccionada',
            youChoosedAExclusiveCampainThatWasChoosedBefore: `Ha seleccionado una campaña, pero ya se ha seleccionado una campaña exclusiva.
            \n¿Desea anular la selección de la campaña única y seleccionarla?`,
            conflictCampain: 'Campaña conflictiva',
            youChoosedAConflictCampain: `Seleccionó una campaña en conflicto con la campaña #NAME.
            \nArtículo en conflicto: #ITEM en #WHERE. 
            \nPara seleccionar esta campaña, anule la selección de la campaña "#NAME".`
        },
        columns: {
            name: 'Nombre',
            jdeCode: 'Código JDE',
            roles: 'Ocupación',
            active: 'Activo',
            login: 'Acceso',
            division: 'División',
            order: 'Solicitud',
            client: 'Cliente',
            condition: 'Condición',
            scheduledDate: 'Fecha programada',
            orderType: 'Tipo de orden',
            hasBonification: '¿Tienes un bono?',
            total: 'Total',
            solicitation: 'Solicitación',
            clientCode: 'Cód. Cliente',
            representerCode: 'Cód. Representante',
            representer: 'Representante',
            discount: 'Descuento',
            totalWithDiscount: 'Totales con descuento',
            seller: 'Vendedor',
            date: 'Fecha',
            clientName: 'Nombre del cliente',
            deadline: 'Plazo',
            totalAmount: 'Valor total',
            pendency: 'Pendiente',
            protocol: 'Protocolo',
            bonusOrder: 'Solicitud de bonificación',
            bonusProtocol: 'Protocolo de bonificación',
            orderDate: 'Fecha de solicitud',
            bonusTotalAmount: 'Importe total de la bonificación',
            user: 'Usuario',
            saleOrder: 'Órdenes de venta',
            saleProtocol: 'Protocolo de venta',
            saleTotalAmount: 'Valor total de ventas',
            customerCity: 'Ciudad del cliente',
            resendEmail: 'Reenviar email',
            forceSync: 'Envío inmediato',
            code: 'Código',
            cia: 'Cia',
            type: 'Grupo de cliente',
            cnpj: 'CUIT',
            city: 'Ciudad',
            state: 'Provincia',
            country: 'País',
            moneyType: 'Moneda',
            branch: 'Subsidiaria',
            orderAmount: 'Orden total',
            item: 'Artículo',
            items: 'Elementos',
            presentation: 'Presentación',
            qtd: 'Cantidad',
            presentationQtd: 'Cant. Presentacion',
            amountQtd: 'Cant. Total',
            price: 'Precio',
            promo: 'Promoción',
            desc: 'Descripción',
            subTotal: 'Total parcial',
            sequence: 'Ordenar',
            clientType: 'Tipo de cliente',
            address: 'Dirección',
            transportCode: 'Cód. Transporte',
            addition: 'Suma',
            bonification: 'Bonificación',
            transportType: 'Tipo de transporte',
            validations: 'Validaciones',
            pandingWith: 'APROBADOR',
            approver: 'Aprobador',
            delegate: 'Delegar',
            startDate: 'Fecha de inicio',
            endDate: 'Fecha de finalización',
            reason: 'Razón',
            delegateReason: 'Delegado de razón',
            description: 'Descripción',
            value: 'Valor',
            itemQtd: 'Cantidad de objetos',
            paymentCondition: 'Condición de pago',
            clientClassification: 'Valoración de los clientes',
            stateOfClient: 'Provincia del cliente',
            finalized: 'Acabado',
            exclusive: 'Exclusivo',
            exclusiveCampain: 'Campaña exclusiva',
            finalizedCampain: '¿Acabado?',
            actions: 'Comportamiento',
            triangulation: 'Triangulación',
            buyOrder: 'Orden de compra',
            deliveryDate: 'Fecha de entrega',
            limit: 'Límite',
            profile: 'Perfil',
            bonificationLimite: 'Límite de bonificación',
            userN: 'Usuario',
            representerChoose: 'Elección representativa',
            lineManager: 'Gerente de línea',
            color: 'Color',
            percent: 'Porcentaje',
            numberItem: 'Número de artículo',
            compkit: 'CompKit',
            controlled: 'Revisado',
            agreement100: 'Acuerdo 100',
            etrade: 'ETrade',
            ipi: 'IPI',
            kit: 'Kit',
            limited: 'Limitado',
            multiply: 'Múltiple',
            ipiValue: 'Valor IPI',
            number: 'Número',
            icmsCredit: 'Crédito ICMS',
            minOrder: 'Pedido mínimo',
            branchCode: 'Código de sucursal',
            campainCode: 'Código de campaña',
            status: 'Estado',
            PENDENTE: 'PENDIENTE',
            APROVADO: "APROBADO",
            RECUSADO: 'RECHAZADO',
            REPROVADO: 'RECHAZADO',
            RASCUNHO: 'BORRADOR',
            PROGRAMADO: "PROGRAMADO",
            INTEGRADO: 'INTEGRADO',
            FATURADO: 'FACTURADO',
            ENTREGUE: 'ENTREGADO',
        },
        filters: {
            all: 'Todos',
            cnpj: 'CUIT',
            name: 'Nombre',
            clientCode: 'Código de cliente',
            clientCod: 'Codigo del cliente',
            client: 'Cliente',
            clientName: 'Nombre del cliente',
            status: 'Estado',
            city: 'Ciudad',
            state: 'Provincia',
            type: 'Grupo de cliente',
            solicitation: 'Solicitación',
            date: 'Fecha',
            division: 'División',
            order: 'Solicitud',
            totalAmount: 'Valor total',
            orderType: 'Tipo de orden',
            protocol: 'Protocolo',
            bonusOrder: 'Solicitud de bonificación',
            bonusProtocol: 'Protocolo de bonificación',
            orderDate: 'Fecha de solicitud',
            bonusTotalAmount: 'Importe total de la bonificación',
            user: 'Usuario',
            saleOrder: 'Órdenes de venta',
            saleProtocol: 'Protocolo de venta',
            saleTotalAmount: 'Valor total de ventas',
            transport: 'Transporte',
            code: 'Código',
            discount: 'Descuento',
            fixBonification: 'Bono fijo',
            proportionalBonification: 'Bono proporcional',
            notification: 'Notificación',
        },
        comparison: {
            equalTo: 'Igual a',
            diferentThen: 'Diferente de',
            biggerThen: 'Más grande que',
            lessThen: 'Menos que',
            contains: 'Contiene',
            noContains: 'No contiene',
            startWith: 'Comienza con',
        },
        months: {
            jan: 'Enero',
            feb: 'Febrero',
            mar: 'Marzo',
            abr: 'Abril',
            mai: 'Mayo',
            jun: 'Junio',
            jul: 'Julio',
            aug: 'Agosto',
            sep: 'Septiembre',
            oct: 'Octubre',
            nov: 'Noviembre',
            dec: 'Diciembre',
        },
        reasons: {
            temporaryAbsent: 'Ausencia temporal',
            vacation: 'Vacaciones',
            maternityLeave: 'Licencia de maternidad',
            medicalLeave: 'Licencia médica',
            other: 'Otros',
            travel: 'Viaje',
        },
    },
};
