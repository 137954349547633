import moment from 'moment';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import axios from '@axios';
import { RootState } from '@redux/reducers';
import { IOrderItemsSelectorState } from '.';
import { OrderRequest } from '@models/order/items/request';
import { PaginatedResponseApi } from '@models/order/items/response';
import { DraftResponseApi } from '@models/order/drafs/response';
import { toQueryParameters } from '@extensions/object';
import { CustomerRequest } from '@models/customer';
import { Paginated } from '@models/paginated';
import { exportReport } from '@utils/file';
import { translate } from '@components/i18n';
import { formatMoney } from '@utils/money';

export type ScheduledResponse = IOrderItemsSelectorState;

export interface ScheduledTable {
    id: string;
    draftRef: string;
    name: string;
    condition: string;
    type: string;
    total: string;
    lastUpdate: string;
}

export const fetchScheduled = createAsyncThunk<Partial<ScheduledResponse>[]>(
    'scheduled/fetchScheduled',
    async (request, thunkAPI) => {
        try {
            const response = await axios.get<
                PaginatedResponseApi<DraftResponseApi>
            >('/pedido/list/programados?size=999');

            const { data, status } = response;
            console.debug(data, status, 'programados');
            if (status === 200) {
                const result = response.data.content.map((u) =>
                    Object.assign(new DraftResponseApi(), u),
                );

                return result.map((d) => d.toOrderState());
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            const { response } = e;
            const { data } = response;
            const { error } = data;
            const { message } = error;

            return thunkAPI.rejectWithValue(message);
        }
    },
);

export const fetchScheduledExport = createAsyncThunk<
    Partial<ScheduledResponse>[]
>('scheduled/fetchScheduled', async (request, thunkAPI) => {
    try {
        const response = await axios.get(
            `/relatorio/pedidosprogramados/export`,
            { responseType: 'blob' },
        );
        exportReport(
            response.data,
            `${translate('general.reportScheduledOrders')}.xlsx`,
        );
    } catch (e) {
        const { response } = e;
        const { data } = response;
        const { error } = data;
        const { message } = error;

        return thunkAPI.rejectWithValue(message);
    }
});

export const fetchDashboardScheduled = createAsyncThunk<
    Paginated<Partial<ScheduledResponse>>,
    CustomerRequest
>('scheduled/fetchDashboardScheduled', async (request, thunkAPI) => {
    try {
        const state = thunkAPI.getState() as RootState;
        const { scheduled } = state;
        const { request: oldRequest } = scheduled;

        if (oldRequest) {
            const { company, filterValue, filterBy } = oldRequest;
            if (
                company != request.company ||
                filterValue != request.filterValue ||
                filterBy != request.filterBy
            )
                request.current = 1;
        }

        thunkAPI.dispatch(updateScheduledRequest(request));

        const params = toQueryParameters({
            companhiaCodigo: request.company,
            filterValue: request.filterValue,
            filterType: request.filterBy?.toUpperCase()
                ? request.filterBy?.toUpperCase()
                : '',
            page: request.current - 1,
            size: request.pageSize < 10 ? 10 : request.pageSize,
            sort: 'dataPedido,desc',
        });

        const response = await axios.get<
            PaginatedResponseApi<DraftResponseApi>
        >(
            `/pedido/list/programados?${params}&sort=dataProgramada,asc&sort=id,desc`,
        );

        const { data } = response;

        if (!data) {
            return {
                items: [],
                current: 0,
                total: 0,
                pageSize: 0,
            };
        } else {
            const result = response.data.content.map((u) =>
                Object.assign(new DraftResponseApi(), u),
            );

            return {
                items: result.map((d) => d.toOrderState()),
                current: request.current,
                total: response.data.totalElements,
                pageSize: request.pageSize < 10 ? 10 : request.pageSize,
            };
        }
    } catch (e) {
        console.debug(e);
        const { response } = e;
        const { data } = response;
        const { error } = data;
        const { message } = error;

        return thunkAPI.rejectWithValue(message);
    }
});

interface IScheduledState {
    scheduled: ScheduledResponse[];
    scheduledDashboard: ScheduledResponse[];
    sendedOrders: OrderRequest[];
    request: CustomerRequest;
}

const initialState: IScheduledState = {
    scheduled: [],
    sendedOrders: [],
    scheduledDashboard: [],
    request: {
        pageSize: 10,
        current: 1,
        company: '',
        filterBy: '',
        filterValue: '',
        categoria: ''
    },
};

const scheduledSlice = createSlice({
    name: 'scheduledSlice',
    initialState,
    reducers: {
        updateScheduledRequest: (
            state,
            action: PayloadAction<CustomerRequest>,
        ) => {
            state.request = action.payload;

            return state;
        },
    },
    extraReducers: {
        [fetchScheduled.fulfilled.toString()]: (
            state,
            { payload }: PayloadAction<ScheduledResponse[]>,
        ) => {
            state.scheduled = payload;
            return state;
        },
        [fetchDashboardScheduled.fulfilled.toString()]: (
            state,
            { payload }: PayloadAction<Paginated<ScheduledResponse>>,
        ) => {
            state.scheduledDashboard = [...payload.items];
            state.request = {
                ...state.request,
                total: payload.total,
                pageSize: payload.pageSize,
                current: payload.current,
            };
            return state;
        },
    },
});

export const scheduledSelector = (state: RootState): IScheduledState =>
    state.scheduled;

export const scheduledValues = (state: RootState): ScheduledTable[] =>
    state.scheduled.scheduled
        .filter((o) => o.order.orderType.type.id !== 'V1')
        .map<ScheduledTable>((d) => ({
            id: `${d.draftRef}`,
            condition: d.order.paymentCondition
                ? d.order.paymentCondition.description
                : translate('general.notSelected'),
            draftRef: d.order.orderType.date
                ? moment(d.order.orderType.date).format('DD/MM/YYYY')
                : translate('general.notInformed'),
            lastUpdate: moment(d.lastUpdate).format('DD/MM/YYYY'),
            name: d.order.customer.name,
            total: formatMoney(state.selectItems?.selectedMoeda?.simbolo, d.order.valorPedido),
            type: d.order.orderType.type.description,
            hasBonification: d.order.bonificationId
                ? translate('general.yes').toLocaleUpperCase()
                : translate('general.no').toLocaleUpperCase(),
        }));

export const scheduledDashboardValues = (state: RootState): ScheduledTable[] =>
    (state.scheduled.scheduledDashboard ?? [])
        .filter((o) => o.order.orderType.type.id !== 'V1')
        .map<ScheduledTable>((d) => ({
            id: `${d.draftRef}`,
            condition: d.order.paymentCondition
                ? d.order.paymentCondition.description
                : translate('general.notSelected'),
            draftRef: d.order.orderType.date
                ? moment(d.order.orderType.date).format('DD/MM/YYYY')
                : translate('general.notInformed'),
            lastUpdate: moment(d.lastUpdate).format('DD/MM/YYYY'),
            name: d.order.customer.name,
            total: formatMoney(state.selectItems?.selectedMoeda?.simbolo, d.order.valorPedido),
            type: d.order.orderType.type.description,
            hasBonification: d.order.bonificationId
                ? translate('general.yes').toLocaleUpperCase()
                : translate('general.no').toLocaleUpperCase(),
        }));

export const { updateScheduledRequest } = scheduledSlice.actions;
export default scheduledSlice.reducer;
