import { default as authRoutes } from './auth';
import { default as homeRoutes } from './home';
import { default as orderRoutes } from './order';
import { default as userRoutes } from './user';
import { default as reportRoutes } from './report';
import { default as campaignRoutes } from './campaign';
import { default as minOrderRoutes } from './min-order';
import { default as configRoutes } from './config';
import { default as financialRoutes } from './financial-discounts';
import { default as registerRoutes } from './register';

import { APP_URL_API } from '@config/env';
import { RouterPath } from '@definitions/routes';

// Combined routes
export const routes: RouterPath[] = [
    ...authRoutes,
    ...homeRoutes,
    ...orderRoutes,
    ...userRoutes,
    ...reportRoutes,
    ...campaignRoutes,
    ...minOrderRoutes,
    ...configRoutes,
    ...financialRoutes,
    ...registerRoutes,
];

// API Routes
export const routeApi = APP_URL_API;

// Image
export const routeImage = APP_URL_API;
