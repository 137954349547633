/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { translate } from '@components/i18n';
import { EditableField } from '@models/editable-field';
import { CustomButton } from '@pages/approval-groups/styled';
import { campaignSelector } from '@redux/slices/campaign';
import { Button, Row } from 'antd';
import moment from 'moment';
import { useSelector } from 'react-redux';

export const campaignColumns = [
    {
        title: translate('columns.name'),
        dataIndex: 'nome',
        sorter: false,
        width: '30%',
    },
    {
        title: translate('columns.finalized'),
        dataIndex: 'finalizado',
        sorter: false,
        width: '10%',
        render: (_, record) => {
            return (
                <span>
                    {record.finalizado
                        ? translate('general.yes')
                        : translate('general.no')}
                </span>
            );
        },
    },
    {
        title: translate('columns.startDate'),
        dataIndex: 'dataInicio',
        sorter: false,
        width: '15%',
    },
    {
        title: translate('columns.endDate'),
        dataIndex: 'dataFim',
        sorter: false,
        width: '15%',
    },
    {
        title: translate('columns.exclusive'),
        dataIndex: 'exclusivo',
        sorter: false,
        width: '12%',
        render: (_, record): React.ReactNode => {
            return (
                <span>
                    {record.exclusivo
                        ? translate('general.yes')
                        : translate('general.no')}
                </span>
            );
        },
    },
    {
        title: translate('columns.type'),
        dataIndex: 'tipo',
        sorter: false,
        width: '12%',
    },
];

export const resultOptions = [
    {
        id: 'DESCONTO',
        name: translate('filters.discount'),
    },
    {
        id: 'BONIFICACAO_FIXA',
        name: translate('filters.fixBonification'),
    },
    {
        id: 'BONIFICACAO_PROPORCIONAL',
        name: translate('filters.proportionalBonification'),
    },
    {
        id: 'NOTIFICACAO',
        name: translate('filters.notification'),
    },
];

export function campaignEditableFields({
    handleAddField,
    handleAddResults,
    group,
    divisionOptions,
}): EditableField[] {
    const campaignState = useSelector(campaignSelector);

    const fields = [
        {
            name: 'nome',
            label: translate('columns.name'),
            type: 'text',
            span: 8,
            rules: [
                {
                    required: true,
                    message: translate('forms.rules.campainName'),
                },
                {
                    validator: (_, value: string) => {
                        if (
                            value &&
                            campaignState.availableCampaign.some(
                                ({ nome, id }) =>
                                    nome?.toLowerCase().trim() ===
                                        value.toLowerCase().trim() &&
                                    (!group || group.id !== id),
                            )
                        ) {
                            return Promise.reject(
                                translate('general.existCampainWithThisName'),
                            );
                        }
                        return Promise.resolve();
                    },
                },
            ],
        },
        {
            name: 'exclusivo',
            label: translate('columns.exclusiveCampain'),
            type: 'boolean',
            span: 8,
        },
        {
            name: 'finalizado',
            label: translate('columns.finalizedCampain'),
            type: 'boolean',
            span: 8,
        },
        {
            name: 'dataInicio',
            label: translate('columns.startDate'),
            type: 'date',
            format: 'DD/MM/YYYY',
            span: 8,
            rules: [
                {
                    required: true,
                    message: translate('forms.rules.startDate'),
                },
                ({getFieldValue}) => ({
                    validator(_, value) {
                        if (value && moment(value).isAfter(
                            moment(getFieldValue('dataFim'),
                            'day',
                        ))) {
                            return Promise.reject(
                                new Error(
                                    translate(
                                        'forms.rules.startDateCannotBeAfterEnd',
                                    ),
                                ),
                            );
                        }
                        return Promise.resolve();
                    },
                }),
            ],
        },
        {
            name: 'dataFim',
            label: translate('columns.endDate'),
            type: 'date',
            format: 'DD/MM/YYYY',
            span: 8,
            rules: [
                {
                    required: true,
                    message: translate('forms.rules.endDate'),
                },
                ({ getFieldValue }) => ({
                    validator(_, value) {
                        if (
                            value &&
                            moment(getFieldValue('dataInicio')).isAfter(
                                moment(value),
                                'day',
                            )
                        ) {
                            return Promise.reject(
                                new Error(
                                    translate(
                                        'forms.rules.endDateCannotBeBeforeStart',
                                    ),
                                ),
                            );
                        }
                        return Promise.resolve();
                    },
                }),
            ],
        },
        {
            name: 'divisao',
            label: translate('forms.labels.division'),
            type: 'select',
            multiple: false,
            span: 8,
            options: divisionOptions,
            rules: [
                {
                    required: true,
                    message: translate('forms.rules.division'),
                },
            ],
        },
        {
            title: translate('general.campainCondition'),
            name: 'hr',
            label: 'hr',
        },
        {
            name: 'condicoes',
            label: translate('forms.labels.orderType'),
            type: 'component',
            span: 24,
            Render: () => (
                <CustomButton>
                    <Button onClick={handleAddField} className="border-none">
                        <PlusCircleOutlined />
                        <span className="ml-2">
                            {translate('forms.buttons.addCondition')}
                        </span>
                    </Button>
                </CustomButton>
            ),
        },
        {
            title: translate('forms.labels.results'),
            name: 'hr',
            label: 'hr',
        },
        {
            name: 'tipo',
            label: '',
            type: 'radio',
            rules: [
                {
                    required: true,
                    message: translate('forms.rules.selectedCampain'),
                },
            ],
            options: resultOptions,
            span: 24,
        },
        {
            name: 'resultados',
            label: translate('forms.labels.results'),
            type: 'component',
            span: 24,
            Render: () => (
                <CustomButton>
                    <Button onClick={handleAddResults} className="border-none">
                        <PlusCircleOutlined />
                        <span className="ml-2">
                            {translate('forms.buttons.add')}
                        </span>
                    </Button>
                </CustomButton>
            ),
        },
    ];

    return fields;
}

export const conditionsFields = [
    {
        id: 'QUANTIDADE_ITEM',
        name: translate('columns.itemQtd'),
    },
    {
        id: 'CONDICAO_PAGAMENTO',
        name: translate('columns.paymentCondition'),
    },
    {
        id: 'FILIAL',
        name: translate('columns.branch'),
    },
    {
        id: 'TIPO_CLIENTE',
        name: translate('columns.clientType'),
    },
    {
        id: 'CLASSIFICACAO_CLIENTE',
        name: translate('columns.clientClassification'),
    },
    {
        id: 'ESTADO_CLIENTE',
        name: translate('columns.stateOfClient'),
    },
];

export const operators = [
    {
        id: 'EQ',
        name: translate('comparison.equalTo'),
    },
    {
        id: 'NEQ',
        name: translate('comparison.diferentThen'),
    },
];

export const conditionsJsonModel = ({
    handleRemove,
    indexFieldApprovers,
    options,
    numberOptions,
}): EditableField[] => {
    return [
        {
            name: `c.campo.${indexFieldApprovers}`,
            label: translate('forms.labels.field'),
            type: 'select',
            span: 5,
            options: conditionsFields,
            order: indexFieldApprovers,
            rules: [
                {
                    required: true,
                    message: translate('forms.rules.field'),
                },
            ],
        },
        {
            name: `c.numeroItem.${indexFieldApprovers}`,
            label: translate('forms.labels.numberItem'),
            type: 'select',
            span: 4,
            order: indexFieldApprovers,
            options: numberOptions,
            rules: [
                ({ getFieldValue }) => ({
                    validator(_, value) {
                        if (
                            getFieldValue(`campo.${indexFieldApprovers}`) ===
                                'QUANTIDADE_ITEM' &&
                            !value
                        ) {
                            return Promise.reject(
                                new Error(translate('forms.rules.numberItem')),
                            );
                        }
                        return Promise.resolve();
                    },
                }),
            ],
        },
        {
            name: `c.operador.${indexFieldApprovers}`,
            label: translate('forms.labels.operator'),
            type: 'select',
            span: 4,
            options: operators,
            order: indexFieldApprovers,
            rules: [
                {
                    required: true,
                    message: translate('forms.rules.operator'),
                },
            ],
        },
        {
            name: `c.valor.${indexFieldApprovers}`,
            label: translate('forms.labels.value'),
            type: 'select',
            multiple: true,
            options: options,
            span: 7,
            order: indexFieldApprovers,
            rules: [
                {
                    required: true,
                    message: translate('forms.rules.chooseValue'),
                },
            ],
        },
        {
            name: `c.hr.${indexFieldApprovers}`,
            label: 'hr',
            type: 'component',
            span: 4,
            Render: () => (
                <Row style={{ height: '100%' }} align={'middle'}>
                    <CustomButton style={{ marginBottom: 0 }}>
                        <Button
                            onClick={() => {
                                handleRemove(indexFieldApprovers);
                            }}
                            className="border-none"
                        >
                            <MinusCircleOutlined />
                        </Button>
                    </CustomButton>
                </Row>
            ),
        },
    ];
};

export const resultsJsonModel = ({
    handleRemove,
    indexFieldApprovers,
    tipo,
    numberOptions,
}): EditableField[] => {
    return [
        {
            name: `r.numeroItem.${indexFieldApprovers}`,
            label: translate('forms.labels.numberItem'),
            type: 'select',
            span: 6,
            options: numberOptions,
            order: indexFieldApprovers,
            removed: tipo === 'NOTIFICACAO',
            rules: [
                {
                    required: true,
                    message: translate('forms.rules.numberItem'),
                },
            ],
        },
        {
            name: `r.qtd.${indexFieldApprovers}`,
            label: translate('forms.labels.qtd'),
            type: 'number',
            span: 6,
            order: indexFieldApprovers,
            removed: tipo === 'NOTIFICACAO',
            rules: [
                {
                    required: true,
                    message: translate('forms.rules.qtd'),
                },
            ],
        },
        {
            name: `r.desconto.${indexFieldApprovers}`,
            label: `${translate('columns.discount')} (-100 - 100)`,
            type: 'number',
            span: 6,
            order: indexFieldApprovers,
            min: -100,
            max: 100,
            removed: tipo !== 'DESCONTO',
            rules: [
                {
                    required: true,
                    message: translate('forms.rules.qtd'),
                },
            ],
        },
        {
            name: `r.email.${indexFieldApprovers}`,
            label: translate('forms.labels.email'),
            type: 'text',
            removed: tipo !== 'NOTIFICACAO',
            span: 16,
            rules: [
                {
                    required: true,
                    message: translate('forms.rules.email'),
                },
                {
                    type: 'email',
                    message: translate('forms.rules.validEmail'),
                },
            ],
        },
        {
            name: `r.hr.${indexFieldApprovers}`,
            label: 'hr',
            type: 'component',
            span: 4,
            Render: () => (
                <Row style={{ height: '100%' }} align={'middle'}>
                    <CustomButton style={{ marginBottom: 0 }}>
                        <Button
                            onClick={() => handleRemove(indexFieldApprovers)}
                            className="border-none"
                        >
                            <MinusCircleOutlined />
                        </Button>
                    </CustomButton>
                </Row>
            ),
        },
    ];
};

export const brazilianStates = [
    'AC',
    'AL',
    'AP',
    'AM',
    'BA',
    'CE',
    'DF',
    'ES',
    'GO',
    'MA',
    'MT',
    'MS',
    'MG',
    'PA',
    'PB',
    'PR',
    'PE',
    'PI',
    'RJ',
    'RN',
    'RS',
    'RO',
    'RR',
    'SC',
    'SP',
    'SE',
    'TO',
];
