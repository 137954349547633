import axios from '@axios';
import { DraftResponseApi } from '@models/order/drafs/response';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { ApprovalContext, IApprovalContext } from './context';
import { useParams } from 'react-router-dom';
import { CommentResponseApi } from '@models/comments/response';
import { message } from 'antd';
import { AxiosResponse } from 'axios';
import { useQuery as useQueryFn } from '@hooks/use-query';
import { CampaignResponse, CampaignResponseApi } from '@models/campaign';
import moment from 'moment';
import { translate } from '@components/i18n';
import { useDispatch } from 'react-redux';
import { fetchMoedas, fetchMoedasSimbolos } from '@redux/slices/order';

interface ParamProps {
    id?: string;
}

export const ApprovalContextProvider: React.FC = ({ children }) => {
    const { id } = useParams<ParamProps>();
    const queryClient = useQueryClient();
    const query = useQueryFn();
    const [campaigns, setCampaigns] = useState<CampaignResponse[]>([]);
    const [bonificationId, setBonificationId] = React.useState<number>(null);
    const [bonificationsId, setBonificationsId] = React.useState<number[]>([]);
    const dispatch = useDispatch()

    const {
        data: comments,
        isFetched: isFetchedComments,
        isFetching: isFetchingComments,
        refetch,
    } = useQuery('comments', async () => {
        const result = await axios.get<CommentResponseApi[]>(
            `/pedido/${id}/comentario`,
        );

        const data = result.data.map((c) =>
            Object.assign(new CommentResponseApi(), c),
        );

        return data
            .map((c) => c.toCommentResponse())
            .sort((a, b) => moment(a.date).toDate().valueOf() - moment(b.date).toDate().valueOf());
    }, {enabled: !!id})

    const {
        data,
        isFetched,
        isFetching,
        refetch: refetchOrder,
    } = useQuery('order', async () => {
        const result = await axios.get<DraftResponseApi>(`/pedido/${id}`);

        const data = Object.assign(new DraftResponseApi(), result.data);

        return data.toOrderState();
    }, {enabled: !!id});

    const { data: allBonificatedOrders } = useQuery(
        ['order-bonifications', bonificationsId],
        async ({ queryKey }) => {
            const [_, bonificationsId] = queryKey;

            if (!bonificationsId || bonificationsId.length === 0) return [];
            const list = [];
            for (const bonification of bonificationsId) {
                if (!bonification || `${bonification}` === `${id}`) continue;
                const result = await axios.get<DraftResponseApi>(
                    `/pedido/${bonification}`,
                );

                const data = Object.assign(new DraftResponseApi(), result.data);

                list.push(data.toOrderState());
            }

            return list;
        },{enabled: !!id}
    );

    const { data: dataBonification } = useQuery(
        ['order-bonification', bonificationId],
        async ({ queryKey }) => {
            const [_, bonificationId] = queryKey;
            const result = await axios.get<DraftResponseApi>(
                `/pedido/${bonificationId}`,
            );

            const dataResult = Object.assign(
                new DraftResponseApi(),
                result.data,
            );

            if (dataResult.pedidosBonificados) {
                setBonificationsId(dataResult.pedidosBonificados);
            }

            return dataResult.toOrderState();
        },{enabled: !!id}
    );

    useEffect(() => {
        let campanhaIds = [];
        if (data?.order) {
            campanhaIds = [...data.order.campanhaIds];

            if (allBonificatedOrders && allBonificatedOrders.length > 0) {
                for (const order of allBonificatedOrders)
                    if (
                        order.order.campanhaIds &&
                        order.order.campanhaIds.length > 0
                    )
                        campanhaIds.push(...order.order.campanhaIds);
            }
            const set = new Set(campanhaIds);
            if (set.size > 0) loadCampaigns([...set]);
        }
    }, [dataBonification, allBonificatedOrders, data]);

    const loadCampaigns = async (ids: number[]) => {
        const campanhas = [];
        for (const campaign of ids) {
            const campanha = await axios.get<CampaignResponseApi>(
                `/campanha/${campaign}`,
            );

            const { data } = campanha;
            const result = Object.assign(new CampaignResponseApi(), data);

            campanhas.push(result.toCampaignResponse());
        }
        console.debug(campanhas);
        setCampaigns(campanhas);
    };

    const createComment = ({
        text,
        item,
        orderId,
    }): Promise<AxiosResponse<void>> => {
        return axios.post<void>(`/pedido/${orderId ?? id}/comentario`, {
            pedidoId: id,
            texto: text,
            usuarioId: 1,
            numeroItem: item,
        });
    };

    const removeComment = ({
        commentId,
        orderId,
    }): Promise<AxiosResponse<void>> => {
        return axios.delete<void>(
            `/pedido/${orderId ?? id}/comentario/${commentId}`,
        );
    };

    const { mutate, isLoading: isSaving } = useMutation(createComment, {
        onSuccess: (data) => {
            console.debug(data);
            message.success(translate('general.commentSave'));
            return queryClient.invalidateQueries(['comments']);
        },
        onError: () => {
            message.error(translate('general.errorComment'));
        },
        onSettled: () => {
            queryClient.invalidateQueries('create');
        },
    });

    const { mutate: removeMutation } = useMutation(removeComment, {
        onSuccess: (data) => {
            console.debug(data);
            message.success(translate('general.deleteComment'));
            return queryClient.invalidateQueries(['comments']);
        },
        onError: () => {
            message.error(translate('general.errorComment'));
        },
        onSettled: () => {
            queryClient.invalidateQueries('create');
        },
    });

    const refuseOrder = ({ text }): Promise<AxiosResponse<void>> => {
        return axios.post<void>('/pedido/reprovacao', {
            pedidoId: id,
            comentario: text,
        });
    };

    const { mutate: refuseMutation } = useMutation(refuseOrder, {
        onSuccess: (data) => {
            console.debug(data);
            message.success(translate('general.refusedOrder'));
            location.replace('/approval-orders');
        },
        onError: () => {
            message.error(translate('general.errorRefuseOrder'));
        },
        onSettled: () => {
            queryClient.invalidateQueries('create');
        },
    });

    const acceptOrder = (): Promise<AxiosResponse<void>> => {
        return axios.post<void>('/pedido/aprovacao', {
            pedidoId: id,
        });
    };

    const { mutate: acceptMutation } = useMutation(acceptOrder, {
        onSuccess: (data) => {
            console.debug(data);
            message.success(translate('general.acceptOrder'));
            location.replace('/approval-orders');
        },
        onError: () => {
            message.error(translate('general.errorAcceptOrder'));
        },
        onSettled: () => {
            queryClient.invalidateQueries('create');
        },
    });

    useEffect(() => {
        if(id){
            dispatch(fetchMoedas())
            dispatch(fetchMoedasSimbolos())
            refetchOrder();
            refetch();
        }
    }, [id]);

    useEffect(() => {
        if (data && data.order.bonificationId) {
            setBonificationId(data.order.bonificationId);
        } else {
            setBonificationId(null);
        }
    }, [data]);

    const state: IApprovalContext = {
        order: data?.order,
        loading: isFetching,
        success: isFetched,
        comments,
        successComments: isFetchedComments,
        loadingComments: isFetchingComments,
        addComment: mutate,
        isSavingComment: isSaving,
        refuseOrder: refuseMutation,
        acceptOrder: acceptMutation,
        readOnly: data?.order?.status === 'APROVADO' || !!query.get('readOnly'),
        bonificationOrder: dataBonification?.order,
        allBonificationOrders: allBonificatedOrders?.map((o) => o.order),
        removeComment: removeMutation,
        campaigns: campaigns,
    };

    return (
        <ApprovalContext.Provider value={state}>
            {children}
        </ApprovalContext.Provider>
    );
};
